import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "numerals"
    }}>{`Numerals`}</h1>
    <p>{`Always use numerals when referring to numbers in the UI, including 0 and 1-9. Use numerals for
numerical data in tables as well.`}</p>
    <p>{`The only exception is when you are using "one" in a phrase where it is serving as an article,
adjective, or pronoun, such as "one of these" or "one-track mind." In this case, spell out "one" and
don't use the numeral form.`}</p>
    <p>{`For more, see: `}<a parentName="p" {...{
        "href": "/guidelines/content/numbers/dates-times"
      }}>{`Dates and Times Guidelines`}</a>{` and
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/grids"
      }}>{`Tables`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      